<template>
  <div class="ship-info-detail-add">
    <van-row>
      <van-col>
        <div class="baseInfo">
          船舶基本信息
        </div>
      </van-col>
    </van-row>
    <van-form @submit="saveShipInfo()" alidate-first @failed="onFailed" :rules="shipinfoForm">
      <van-row>
        <van-col span="24">
          <div class="name">
            <van-cell-group>
              <van-field v-model="shipInfo.vesselName" :rules="shipinfoForm.vesselName" input-align="right"
                         label="*船名:"/>
            </van-cell-group>
          </div>
          <div class="fzr">
            <van-cell-group>
              <van-field v-model="shipInfo.charterer" :rules="shipinfoForm.charterer" input-align="right"
                         label="*租船人:"/>
            </van-cell-group>
          </div>
          <div class="company">
            <van-cell-group>
              <van-field v-model="shipInfo.vesselCompany" :rules="shipinfoForm.vesselCompany" input-align="right"
                         label="*船舶公司:"/>
            </van-cell-group>
          </div>
          <div class="hzcs">
            <van-cell-group>
              <van-field v-model="shipInfo.cooperationTimes" input-align="right"
                         type="number"
                         label="*合作次数:"/>
            </van-cell-group>
          </div>
          <div class="ton">
            <van-row>
              <van-col span="23">
                <van-cell-group>
                  <van-field placeholder="请输入数字" v-model="shipInfo.dwt" input-align="right" type="number"
                             label="*载重吨:"/>
                </van-cell-group>
              </van-col>
              <van-col span="1">
                <div class="span">t</div>
              </van-col>
            </van-row>
          </div>
          <div class="length">
            <van-row>
              <van-col span="23">
                <van-cell-group>
                  <van-field placeholder="请输入数字" v-model="shipInfo.length" input-align="right" type="number"
                             label="*船长:"/>
                </van-cell-group>
              </van-col>
              <van-col span="1">
                <div class="span">m</div>
              </van-col>
            </van-row>
          </div>
          <div class="mzcs">
            <van-row>
              <van-col span="23">
                <van-cell-group>
                  <van-field placeholder="请输入数字" v-model="shipInfo.draught" input-align="right" type="number"
                             label="*满载吃水:"/>
                </van-cell-group>
              </van-col>
              <van-col span="1">
                <div class="span">m</div>
              </van-col>
            </van-row>
          </div>
          <div class="cks">
            <van-row>
              <van-col span="23">
                <van-cell-group>
                  <van-field placeholder="请输入数字" v-model="shipInfo.hatch" input-align="right" type="number"
                             label="*舱口数:"/>
                </van-cell-group>
              </van-col>
              <van-col span="1">
                <div class="span1">个</div>
              </van-col>
            </van-row>
          </div>
          <div class="createYear">
            <van-cell-group>
              <van-field v-model="shipInfo.period" input-align="right" label="*建造年限:"/>
            </van-cell-group>
          </div>
          <div class="majorHx">
            <van-cell-group>
              <van-field v-model="shipInfo.mainRoutes" input-align="right" label="*主要航线:"/>
            </van-cell-group>
          </div>
          <div class="shipType">
            <van-cell-group>
              <van-field v-model="shipInfo.vesselType" input-align="right" label="*船舶种类:"/>
            </van-cell-group>
          </div>
          <div class="majorHz">
            <van-cell-group>
              <van-field v-model="shipInfo.mainGoods" input-align="right" label="*主要货种:"/>
            </van-cell-group>
          </div>
          <div class="zcr">
            <van-cell-group>
              <van-field v-model="shipInfo.toalCapacity" input-align="right" label="*总舱容:"/>
            </van-cell-group>
          </div>
          <div class="cr">
            <van-cell-group>
              <van-field v-model="shipInfo.capacity" input-align="right" label="*舱容:"/>
            </van-cell-group>
          </div>
        </van-col>
      </van-row>
      <van-button type="primary" size="large" class="footer-bar">保存</van-button>
      <!--      <crm-tab-bar :tabpage="5"/>-->
    </van-form>
  </div>
</template>
<script>
import CrmTabBar from "@/components/CrmTabBar";

export default {
  name: "BulkCrmsShipInfoDetailAdd",
  components: {CrmTabBar},
  data() {
    return {
      shipInfo: {
        vesselCompany: '',
        vesselName: '',
        charterer: '',
        cooperationTimes: '',
        dwt: '',
        length: '',
        draught: '',
        hatch: '',
        period: '',
        mainRoutes: '',
        vesselType: '',
        mainGoods: '',
        toalCapacity: '',
        capacity: '',
      },
      shipinfoForm: {
        vesselCompany: [
          {required: true, message: '请输入船舶公司名称', trigger: 'blur'},
        ],
        vesselName: [
          {required: true, message: '请输入船名', trigger: 'blur'},
        ],
        charterer: [
          {required: true, message: '请输入租船人', trigger: 'blur'},
        ],
        cooperationTimes: [
          {required: true, message: '请输入合作次数', trigger: 'blur'},
        ],
      },
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
    },
    saveShipInfo() {
      console.log(this.shipInfo)
      this.$ajax.post('/api/ajax/bulkCrmShipinfo/saveBulkShipinfotwo.json',
          {
            shipInformationid: this.$route.params.id,
            vesselName: this.shipInfo.vesselName,
            charterer: this.shipInfo.charterer,
            vesselCompany: this.shipInfo.vesselCompany,
            cooperationTimes: this.shipInfo.cooperationTimes,
            dwt: this.shipInfo.dwt,
            length: this.shipInfo.length,
            draught: this.shipInfo.draught,
            hatch: this.shipInfo.hatch,
            period: this.shipInfo.period,
            mainRoutes: this.shipInfo.mainRoutes,
            vesselType: this.shipInfo.vesselType,
            mainGoods: this.shipInfo.mainGoods,
            toalCapacity: this.shipInfo.toalCapacity,
            capacity: this.shipInfo.capacity,
            createdPeople: this.$store.getters.userId,
          }).then(res => {
        if (res.status == 200) {
          this.$message({
            showClose: true,
            message: '添加成功',
            type: 'success'
          });
          sessionStorage.clear();
          setTimeout(() => {
            if(this.$route.params.id == 'null'){
              this.$router.replace({path: '/index'})
            }else{
              this.$router.replace({path: '/BulkCrmsShipInfoList/' + this.$route.params.id})
            }
          }, 200);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('添加失败');
      })
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
    },
  }
}
</script>
<style scoped>
.ship-info-detail-add {
  background: white;
}

.ship-info-detail-add .baseInfo {
  font-size: 10px;
  color: #BAB8B7;
  margin-top: 10px;
  padding: 15px;
}

.ship-info-detail-add .van-button {
  width: 220px;
  height: 35px;
  border-radius: 5px;
  font-weight: bold;
  font-family: 新宋体;
  margin-top: 130px;
}

.ship-info-detail-add .span {
  padding-top: 13px;
  margin-left: -2px;
}

.ship-info-detail-add .span1 {
  padding-top: 12px;
  margin-left: -2px;
  font-size: 14px;
}

.ship-info-detail-add .total .van-cell {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  padding: 10px 10px 10px 16px;;
  overflow: hidden;
  color: #323233;
  font-size: 14px;
  line-height: 24px;
  background-color: #fff;
}
</style>
<style>
.ship-info-detail-add .duration .van-field__label, .length .van-field__label {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  flex: none;
  box-sizing: border-box;
  width: 8.2em;
  margin-right: 12px;
  color: #646566;
  text-align: left;
  word-wrap: break-word;
}

.ship-info-detail-add .remarks .van-field__control--right, .post .van-field__control--right, .contacts .van-field__control--right, .customer .van-field__control--right, .model .van-field__control--right {
  color: #BAB8B7;
}
</style>
